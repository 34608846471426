.kernel-bar-container {
  position: fixed;
  top: 0;
  /* z-index: 5000; */
  background-color: var(--bg-primary-clr2);
  height: 50px;
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(30px);
  width: 100%;
  box-shadow: var(--header-shadow1);
  padding-block: 4px;
}
.quera-kernel-bar-container {
  box-shadow: none !important;
}

.navigation-menus {
  color: var(--text-primary-clr2);
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  gap: "5px";
}

.admin-navigation-logo-container {
  display: inline-grid;
  place-items: center;
  gap: 10px;
  cursor: pointer;
}
.navigation-logo-container {
  display: inline-grid;
  grid-template-columns: 1fr 2fr;
  place-items: center;
  gap: 10px;
  cursor: pointer;
}

.navigation-logo {
  width: 35px;
  border-radius: 5px;
}
.navigation-logo-quera {
  width: 100px;
}

.navigation-title {
  text-decoration: none;
  font-size: 22px;
  font-weight: 500;
  color: var(--text-primary-clr2);
}

.nav-item {
  margin: 8px;
  font-size: 18px;
  white-space: nowrap;
}
.kernel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.kernel-information {
  color: var(--text-primary-clr2);
  float: right;
  font-family: var(--global-font-family-primary);
  margin-right: 20px;
  display: flex;
  font-weight: 400;
  /* margin-top: 16px; */
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.kernel-name {
  cursor: pointer;
}

.kernel-status-ball {
  border-radius: 5px;
  width: 10px;
  height: 10px;
  /* margin-top: 5px;
	margin-right: 5px;
	margin-left: 30px; */
}

.restart-container {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 2fr;
  cursor: pointer;
}

.restart-btn {
  height: 15px;
  filter: brightness(0.5);
}

.restart-text {
  margin-left: 5px;
}

.account {
  color: var(--text-inverted-clr1);
  background: var(--account-info-icon-bg);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  font-size: 20px;
  margin-top: -6px;
  justify-content: center;
  align-items: center;
}

.sign-out {
  margin-right: 15px;
  cursor: pointer;
}

.kernel-manager-menu {
  height: 150px;
  width: 130px;
  margin-top: 35px;
  background-color: var(--text-primary-clr2);
  border: 1px solid var(--text-primary-clr2);
  border-radius: 15px;
  margin-left: -250px;
  margin-right: 119px;
}
.navigation-menu-container {
  display: block;
}
@media (min-width: 1200px) {
  .navigation-menu-container {
    display: none;
  }
}
.log-in-prompt {
  width: auto;
  padding-right: 1em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-primary-clr2);
}
.log-in-prompt-text {
  margin: 0 !important;
  user-select: none;
  cursor: pointer;
}
@media (max-width: 600px) {
  .navigation-menus {
    margin-left: 10px;
  }

  .nav-item {
    font-size: 16px;
    margin: 10px 7px;
  }

  .kernel-information {
    padding: 5px;
    margin-right: 17px;
  }

  .navigation-logo-container {
    display: block;
  }

  .kernel-name {
    display: none;
  }

  .kernel-status-ball {
    margin-left: 3px;
  }

  .navigation-title {
    display: none;
  }

  .restart-container {
    display: none;
  }

  .account {
    margin-left: 10px;
  }
}

.rotate-icon {
  -webkit-animation: rotate-center 0.6s ease-in-out infinite both;
  animation: rotate-center 0.6s ease-in-out infinite both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
