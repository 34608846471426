.table-sort-icon {
  width: 15px;
  height: 15px;
  padding: 4px;
  filter: opacity(0.6);
  mix-blend-mode: difference;
}
.rotate-refresh-icon {
  -webkit-animation: rotate-center 0.6s ease-in-out infinite both;
  animation: rotate-center 0.6s ease-in-out infinite both;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.role-button.manager {
  background-color: var(--accent-clr2);
}

.role-button.instructor {
  background-color: transparent;
  color: var(--accent-clr2);
  border: "1px solod var(--accent-cl2)";
}
.role-button.instructor:hover {
  background-color: transparent;
}

.role-button.owner {
  background-color: var(--accent-clr1);
}

.role-button.student {
  background-color: transparent;
  color: var(--accent-clr1);
  border: 1px solid var(--accent-clr1);
}
.role-button.student:hover {
  background-color: transparent;
}

.role-button.teachingassistant {
  background-color: var(--accent-clr4);
}
.role-button.manager {
  color: var(--accent-clr4);
  background-color: transparent;
  border: "1px solid var(--accent-clr4)";
}
.role-button.manager:hover {
  background-color: transparent;
}
