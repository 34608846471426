.google-btn {
  width: 100%;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
}

.login-logo {
  display: block;
  height: 25px;
  margin: 40px 0;
  user-select: none;
}
.login-logo-qbraid {
  height: fit-content;
  width: fit-content;
}
.login-logo-qusteam {
  height: 120px;
}

.logo-text {
  font-size: 28px;
  font-weight: 300;
  margin: 20px auto;
  padding-top: 20px;
  text-align: center;
  user-select: none;
}

.login-field {
  height: 3rem;
  color: var(--text-primary-invrt1);
  background-color: #12121294;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-bottom: 0;
  padding-left: 1.6rem;
  font-family: var(--global-font-family-primary), sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
}

.login-field:focus {
  border-style: solid;
  border-color: var(--accent-clr1);
  outline: none;
}

.login-input-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
}

.error-message {
  color: red;
  font-size: 14px;
  padding-top: 5px;
}

.sign-up-link {
  width: 100%;
  font-size: 14px;
  padding-top: 20px;
}

.sign-up-link-text {
  font-family: var(--global-font-family-primary), sans-serif;
  color: var(--accent-clr6);
  background-color: transparent;
  padding: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.sign-up-link-text:hover {
  text-decoration: underline;
}

.forgot-password-link {
  font-size: 12px;
  color: var(--accent-clr2);
  cursor: pointer;
}
