:root {
  --accent-clr1: #c430f1;
  --accent-clr2: #9b12df;
  --accent-clr3: #7606b8;
  --accent-clr4: #b538eb;

  --bg-primary-clr1: #fcfcfc;
  --bg-primary-clr2: #ffffff;
  --bg-primary-clr3: white;
  --bg-secondary-clr1: white;
  --bg-secondary-clr2: white;
  --bg-secondary-clr3: white;
  --bg-error-clr1: #ff4d4f;
  --bg-success-clr1: #52c41a;
  --bg-info-clr1: #1890ff;
  --bg-warning-clr1: #faad14;
  --text-primary-clr1: black;
  --text-primary-clr2: #212121;
  --text-primary-clr3: #1f1f1f;
  --text-secondary-clr1: royalblue;
  --text-secondary-clr2: royalblue;
  --text-secondary-clr3: royalblue;
  --text-inverted-clr1: #ffffff;
  --text-inverted-clr2: #fafafa;
  --text-inverted-clr3: #c0c0c0;
  --text-gray1: #484343;
  --text-gray2: #8e8e8e;
  --text-gray3: #ababab;
  --text-gray4: #767087;
  --text-error1: indianred;
  --text-error2: #ff505d;
  --text-selected: var(--text-inverted-clr1);
  /* admin page related */
  --admin-center-text-bg: var(--bg-primary-clr2);
  --admin-dash-board-shadow: 0px 2px 18px 2px rgba(199, 199, 199, 1);
  --admin-dash-board-shadow1: 0px 2px 18px 2px rgb(224, 222, 222);
  --admin-course-over-view-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.11);
  --admin-course-card-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.11);
  --admin-table-button-hover: #ce93d8;
  --admin-table-button-instructor: #6f00c7;
  --admin-table-button-student: #f0f0f0;
  --admin-table-button-ta: #971cf8c9;
  --admin-table-button-manager: #9b12df;
  --admin-accordion-hover-shadow: #8d36d126;
  --admin-table-row-hover: #8d36d126;
  --admin-linear-progress-bar: var(--accent-clr2);
  --admin-linear-progress-track: #e0abfae8;
  --admin-course-overview-text: #344767;
  /* input text fields */
  --placeholder-clr1: #cccccc;
  /* a tag clr*/
  --a-tag-clr1: var(--accent-clr1);
  /* border clr */
  --border-clr1: var(--accent-clr1);
  --border-clr2: #d9d9d9;
  --border-info-clr1: #1890ff;
  /* font values */
  --global-font-family-primary: "Inter";
  --global-font-family-secondary: "Nunito Sans";
  /* auth page */
  --auth-linear-bg: linear-gradient(120deg, #470871 10%, #d70380 100%);
  --auth-form-bg: #242424;
  --auth-btn-bg1: #9b12df;
  --auth-btn-hov1: #b538eb;
  --auth-btn-shadow1: 0 2px 14px rgb(255 72 249 / 60%);

  --auth-btn-bg2: #b3a7b8;
  --auth-btn-hov2: #ad8bbb;
  --auth-btn-shadow2: 0 2px 14px rgb(255 72 249 / 60%);
  --auth-tex-bg: #2d2d2d;

  --mui-checked: var(--accent-clr3);
  /* header */
  --account-info-icon-bg: linear-gradient(45deg, #9b12da 15%, #ca6af7e9 99%);
  --account-info-popup-bg: linear-gradient(45deg, #d4c0e3 15%, #cf64f9 100%);
  --account-info-popup-icon-bg: linear-gradient(
    45deg,
    #9b12da 15%,
    #9c6bfde9 99%
  );

  /* alert values */
  --alert-bg-success: #f6ffed;
  --alert-text-success: #52c41a;
  --alert-border-success: #b7eb8f;

  --alert-bg-warning: #fffbe6;
  --alert-text-warning: #faad14;
  --alert-border-warning: #ffe58f;

  --alert-bg-info: #fbe6ff;
  --alert-text-info: #9b12df;
  --alert-border-info: #e58fff;

  --alert-bg-error: #fff2f0;
  --alert-text-error: #ff4d4f;
  --alert-border-error: #ffccc7;

  /* mcq related */
  --mcq-question-font-size: 20px;
  --mcq-option-font-size: 18px;
  --mcq-success: green;
  --mcq-failed: indianred;
  --loader-clr1: #8d5bc950;
  --loader-clr2: #b41adb;
  --mcq-loader-clr3: var(--loader-clr2);
  --mcq-hover-clr1: #f3d7f7;

  /* code cell related */
  --play-button-shadow: #9b12da4d;
  --play-button-bg: #ffffff;
  --play-button-border: #d3d3d3;
  --error-cell-bg: #a8000080;

  /* next prev navigation */
  --nav-button-shadow: #9b12da4d;
  --nav-button-bg: #ffffff;
  --nav-button-text: var(--accent-clr2);

  /* markdown related */
  --mark-down-font-size: 18px;
  --mark-down-font-weight: 400;
  --mark-down-anchor-clr: #7d7dad;

  /* blog page related */
  --circle-linear-grd1: #d2137d;
  --circle-linear-grd2: #721eb4;

  --scroll-bar-clr: #000000;

  /* Landing page related */
  --landing-card-bg: #ffffffa6;
  --landing-course-bg: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(152, 36, 194, 1) 0%,
    rgba(160, 202, 255, 1) 93%
  );
  --landing-text-clr1: #515151;
  --landing-Amorphous-gradient1: linear-gradient(
    to right top,
    #f22a9c,
    #ed4bb2,
    #e763c4,
    #e078d5,
    #d98ae2,
    #d78ae8,
    #d48aed,
    #d08bf3,
    #d079f4,
    #d165f4,
    #d34df2,
    #d52af0
  );
  --landing-circle-gradient1: linear-gradient(
    to right top,
    #6666f2,
    #7b5eef,
    #8e55eb,
    #9f4ae5,
    #af3dde,
    #ae33dd,
    #ae27dd,
    #ad16dc,
    #9812e3,
    #7f14ea,
    #5b1af2,
    #0021f9
  );
  --landing-circle-gradient2: linear-gradient(
    to right top,
    #6666f2,
    #7b5eef,
    #8e55eb,
    #9f4ae5,
    #af3dde,
    #ae33dd,
    #ae27dd,
    #ad16dc,
    #9812e3,
    #7f14ea,
    #5b1af2,
    #0021f9
  );
  --landing-circle-gradient3: linear-gradient(
    to right top,
    #0404ff,
    #5e00f6,
    #8100ed,
    #9a00e5,
    #ae06dd,
    #b81bdc,
    #c128dc,
    #c934db,
    #ce3fe1,
    #d349e7,
    #d852ec,
    #dd5bf2
  );
  --landing-label-bg1: #f4d0ff;
  --landing-label-text1: #9812e3;

  --landing-choose-card-bg: var(--landing-card-bg);
  --landing-choose-card-text1: #ffffff;
  --landing-choose-card-text2: #344767;
  --landing-choose-card-text3: #969696;

  --landing-course-card-hover-border: #9812e3;
  --landing-course-card-bg: var(--landing-card-bg);
  --landing-course-card-text1: #9812e3;
  --landing-course-card-text2: #7d7d7d;
  --landing-course-card-text3: #6d6d6d;

  --landing-tutor-card-bg: var(--landing-card-bg);
  --landing-tutor-card-box-shadow: 0 0 30px #00000026;
  --landing-tutor-card-hover-clr: #c241f5aa;
  --landing-page-btn-border-radius: 25px;
  --landing-label-border-radius: 15px;
}
/* ------------------------------------------------------------------------------------ */
[data-quera-mode] {
  --accent-clr1: #6437ff;
  --accent-clr2: #8c61ff;
  --accent-clr3: #443ec3;
  --accent-clr4: #534beb;

  --border-clr2: #dddddd;
  --text-primary-clr1: #101010;
  --text-selected: #c8c8c8;
  /* input text fields */
  --placeholder-clr1: #cccccc;
  /* a tag clr*/
  --a-tag-clr1: var(--accent-clr1);
  /* border clr */
  --border-clr1: var(--accent-clr1);

  --alert-bg-info: #fbe6ff;
  --alert-text-info: #8612df;
  --alert-border-info: #d08fff;

  --blog-page-image-lg1: linear-gradient(to right, #6437ff 20%, #ccc 100%);
  --white: white;
  --quera-yellow: #f0ff48;
  --quera-red: #ff505d;
  /* font values */
  --global-font-family-primary: "RadionB-Demi";
  --global-font-family-secondary: var(--global-font-family-primary);
  /* auth page */
  --auth-linear-bg: linear-gradient(
    105.21deg,
    #2c2644 0%,
    #392d67 7.35%,
    #412a99 18.93%,
    #4a2bbc 33.68%,
    #5441ce 57.57%,
    #6437ff 100%
  );
  --auth-btn-bg1: var(--quera-yellow);
  --auth-btn-hov1: #f0ff48;
  --auth-btn-shadow1: 0 2px 14px rgba(240, 255, 72, 0.6);
  --auth-form-bg: black;

  --auth-btn-bg2: var(--accent-clr1);
  --auth-btn-hov2: #845ffe;
  --auth-btn-shadow2: 0 2px 14px rgba(148, 72, 255, 0.6);
  --auth-btn-shadow-success: 0 2px 14px #b4ec52c0;
  --auth-btn-shadow-error: 0 2px 14px #ec5252a6;
  --auth-btn-shadow-purchase: 0 2px 14px #7d46da9c;
  --mui-checked: var(--quera-yellow);

  /* header */
  --account-info-icon-bg: linear-gradient(
    to right top,
    #5f59f7,
    #5f59f7,
    #5f59f7,
    #5f59f7,
    #5f59f7,
    #635af8,
    #685af8,
    #6c5bf9,
    #755cfb,
    #7d5efc,
    #845ffe,
    #8c61ff
  );
  --account-info-popup-bg: linear-gradient(45deg, #925bf7 15%, #6437ff 100%);
  --account-info-popup-icon-bg: var(--account-info-icon-bg);
  --header-shadow1: rgba(132, 0, 255, 0.181) -4px 5px 7px 11px;

  /* markdown related */
  --mark-down-font-size: 18px;
  --mark-down-font-weight: 200;
  --mark-down-anchor-clr: var(--quera-red);

  /* mcq related */
  --loader-clr1: #9b5bc950;
  --loader-clr2: #7e1adb;
  --mcq-loader-clr3: var(--loader-clr2);
  --mcq-hover-clr1: #d1aaf3;

  /* blog page related */
  --circle-linear-grd1: #6c13d2;
  --circle-linear-grd2: #20112b;
  /* code cell related */
  --play-button-shadow: #7912da4d;
  --play-button-bg: #ffffff;
  --play-button-border: #d3d3d3;

  /* next prev navigation */
  --nav-button-shadow: #7912da4d;
  --nav-button-bg: #ffffff;
  --nav-button-text: var(--accent-clr2);

  /* admin related */
  --admin-table-button-hover: #ac54ffdf;
  --admin-table-button-instructor: #6d00c7;
  --admin-table-button-student: #f0f0f0;
  --admin-table-button-ta: #741cf8c9;
  --admin-table-button-manager: #6e12df;
  --admin-accordion-hover-shadow: #7136d126;
  --admin-table-row-hover: #7136d126;
  --admin-linear-progress-bar: var(--accent-clr2);
  --admin-linear-progress-track: #c172f6f0;

  /* landing page related */
  --landing-Amorphous-gradient1: linear-gradient(
    to right top,
    #8e6bd1,
    #9b76d7,
    #a882dc,
    #b58de2,
    #c199e8,
    #c297ec,
    #c394ef,
    #c492f3,
    #b981f5,
    #ac71f8,
    #9d60fa,
    #8b4ffd
  );
  --landing-circle-gradient1: linear-gradient(
    to right top,
    #9700da,
    #9200e1,
    #8c00e7,
    #8500ee,
    #7c00f5,
    #7c00f5,
    #7d00f6,
    #7d00f6,
    #8600f0,
    #8e00ea,
    #9400e4,
    #9a00de
  );
  --landing-circle-gradient2: linear-gradient(
    to right top,
    #1c00da,
    #360fe1,
    #491be8,
    #5825ef,
    #662ff6,
    #682ff6,
    #6b30f7,
    #6d30f7,
    #6527f1,
    #5d1dea,
    #5511e4,
    #4c00de
  );
  --landing-circle-gradient3: linear-gradient(
    to right top,
    #955eff,
    #8e56fe,
    #874efd,
    #8046fb,
    #783dfa,
    #773bfa,
    #763af9,
    #7538f9,
    #7b3efa,
    #8044fb,
    #8649fc,
    #8b4ffd
  );
  --landing-label-bg1: var(--accent-clr3);
  --landing-label-text1: #ffffff;
  --landing-course-card-text1: var(--accent-clr2);
  --landing-course-card-hover-border: var(--accent-clr2);
  --landing-tutor-card-hover-clr: var(--accent-clr2);
  --landing-page-btn-border-radius: 5px;
  --landing-label-border-radius: 5px;
  --landing-course-bg: linear-gradient(
    90.6871298811709deg,
    #6437ff 19%,
    #9e6ff7 69%,
    #fff4fc 104%,
    #eafc17 121%
  );
}
/* ------------------------------------------------------------------------------------ */
[data-qusteam-mode] {
  --accent-clr1: #4144bb;
  --accent-clr2: #1212df;
  --accent-clr3: #094ddf;
  --accent-clr4: #2961fd;
  --accent-clr5: #ebe6ff;
  --accent-clr6: #8f8fff;

  --text-selected: #c8c8c8;

  --border-clr1: lightgrey;
  --border-clr2: #dddddd;
  --shadow1: #0000001f;
  --shadow2: #dddddd;

  /* font values */
  --global-font-family-primary: "Poppins";
  --global-font-family-secondary: var(--global-font-family-primary);
  /* auth page */
  --auth-linear-bg: linear-gradient(
    100deg,
    rgba(120, 124, 255, 1) 14%,
    rgba(18, 18, 223, 1) 86%
  );
  --auth-btn-bg1: #2a3fff;
  --auth-btn-hov1: #2a3fff;
  --auth-btn-shadow1: 0 4px 14px #696969;

  --auth-btn-bg2: #7b7485;
  --auth-btn-hov2: #afa8ba;
  --auth-btn-shadow2: 0 2px 14px #7b7485;
  --mui-checked: var(--accent-clr3);

  /* header */
  --account-info-icon-bg: linear-gradient(45deg, #1212df 15%, #6c6af7e9 99%);
  --account-info-popup-bg: linear-gradient(45deg, #142cff 15%, #648cf9 100%);
  --account-info-popup-icon-bg: linear-gradient(
    45deg,
    #4412da 15%,
    #648cf9 99%
  );

  /* markdown related */
  --mark-down-font-size: 15px;
  --mark-down-font-weight: 400;
  --mark-down-anchor-clr: #7d7dad;

  /* alert related */
  --alert-bg-info: #fbe6ff;
  --alert-text-info: #1212df;
  --alert-border-info: #8fa4ff;

  /* mcq related */
  --loader-clr1: #5d5bc950;
  --loader-clr2: #1a1adb;
  --mcq-loader-clr3: var(--loader-clr2);
  --mcq-hover-clr1: #aac0f3;

  /* blog page related */
  --circle-linear-grd1: #1329d2;
  --circle-linear-grd2: #233158;
  /* code cell related */
  --play-button-shadow: #121cda4d;
  --play-button-bg: #ffffff;
  --play-button-border: #d3d3d3;
  /* admin page related */
  --admin-table-button-hover: #bb93d8;
  --admin-table-button-instructor: #1700c7;
  --admin-table-button-student: #f0f0f0;
  --admin-table-button-ta: #2e1cf8c9;
  --admin-table-button-manager: #3812df;
  --admin-accordion-hover-shadow: #4336d126;
  --admin-table-row-hover: #4336d126;
  --admin-linear-progress-bar: var(--accent-clr2);
  --admin-linear-progress-track: #afabfae8;

  /* landing page related */
  --landing-Amorphous-gradient1: linear-gradient(
    to right top,
    #0b0bfb,
    #0041ff,
    #005eff,
    #3277fc,
    #588df5,
    #6392f3,
    #6d97f0,
    #769cee,
    #6c93f3,
    #6589f6,
    #607ff9,
    #5f74fb
  );
  --landing-circle-gradient1: linear-gradient(
    to right top,
    #0b0bfb,
    #2010e4,
    #2914cd,
    #2e16b7,
    #3018a1,
    #311aa0,
    #311ca0,
    #321e9f,
    #3123b3,
    #2e28c7,
    #262ddc,
    #1533f2
  );
  --landing-circle-gradient2: linear-gradient(
    to right top,
    #0000ff,
    #1012fa,
    #1b1df6,
    #2325f1,
    #2a2bec,
    #3438ed,
    #3e43ee,
    #474eee,
    #5761f3,
    #6673f8,
    #7685fc,
    #8796ff
  );
  --landing-circle-gradient3: linear-gradient(
    to right top,
    #9696ff,
    #8381ff,
    #706bff,
    #5c55ff,
    #463bfd,
    #443efe,
    #4141fe,
    #3f44ff,
    #5660ff,
    #6d7aff,
    #8592ff,
    #9da9fb
  );
  --landing-label-bg1: var(--accent-clr3);
  --landing-label-text1: #ffffff;
  --landing-course-card-text1: var(--accent-clr2);
  --landing-course-card-hover-border: var(--accent-clr2);
  --landing-tutor-card-hover-clr: var(--accent-clr2);
  --landing-page-btn-border-radius: 25px;
  --landing-label-border-radius: 15px;
  --landing-course-bg: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(36, 55, 194, 1) 0%,
    rgba(160, 202, 255, 1) 93%
  );
}
[data-dark-mode] {
  /* this defines the dark theme if integrated */
  --bg-primary-clr1: #101010;
  --bg-primary-clr2: #151515;
  --bg-primary-clr3: #1b1717;
  --bg-secondary-clr1: black;
  --bg-secondary-clr2: black;
  --bg-secondary-clr3: rgb(62, 62, 62);
  --text-primary-clr1: #ffffff;
  --text-primary-clr2: #efe0e0;
  --text-primary-clr3: #fdfdfd;
  --text-inverted-clr1: #000000d9;
  --text-inverted-clr2: #00000040;
  --text-inverted-clr3: #1f1f1f;
  /* header */

  --header-shadow1: rgb(0, 0, 0) -4px 5px 7px 11px;
  --admin-course-over-view-shadow: 0px 0px 17px 0px rgb(255 255 255 / 11%);
  --admin-course-card-shadow: 5px 7px 17px 0px rgb(252 252 252 / 11%);
  /* admin page related */
  --admin-center-text-bg: #35353rgb(144, 179, 239);
  --admin-course-overview-text: #bcbcbc;
  /* landing page related */
  --landing-text-clr1: #bbb7b7;
  --landing-card-bg: #353535b6;
  --landing-choose-card-text2: #7f7f7f;

  --nav-button-bg: #353535;
  --nav-button-text: var(--text-primary-clr1);
}
