.react-loader {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--bg-primary-clr1);
}
.react-loader-text {
  background: var(--bg-primary-clr1);
  color: var(--text-primary-clr1);
}
