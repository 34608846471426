.profile-details-grid {
  margin-bottom: 1rem;
  padding: 0.59rem;
  border-bottom: 2px solid #eef2f3;
}
.profile-text {
  font-weight: 600 !important;
}
.profile-card {
  /* padding: 1.5rem;  */
  border-radius: 15px;
  margin-top: 2rem !important;
  width: inherit !important;
  height: inherit !important;
  /* border: 1px solid; */
  display: inline-block;
  justify-content: center !important;
}
.user-profile-text {
  font-family: "Inter" !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 1rem !important;
}
.user-profile-subtext {
  font-family: "Inter" !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #696d88 !important;
  margin-bottom: 0.34rem !important;
}
.sec-card {
  width: 100% !important;
  padding: 1rem !important;
  border: 1px solid #c7cacc !important;
  box-sizing: border-box !important;
}
.profile-text-pos {
  margin-left: 3rem !important;
}
.main-img {
  width: 170px;
  height: 170px;
}
.up-img {
  margin-top: 5rem;
  margin-left: 10rem !important;
  margin-bottom: 2rem;
  margin-right: 1.5rem !important;
  justify-content: space-around !important;
}
.textfield {
  width: 480px !important;
}
.pf-imgtext {
  font-size: 12px !important;
  margin-left: 4.5rem !important;
  margin-bottom: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.profile-form {
  align-items: center;
  padding: 0.4rem;
}
.third-grid {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.59rem;
  /* border-bottom: 2px solid #eef2f3; */
}

.grid-text {
  justify-content: flex-start !important;
}
.profile-button {
  border-radius: 5px !important;
  color: #ffffff !important;
  background: #79d35a !important;
  margin-right: 0.75rem !important;
  margin-left: 0.7rem !important;
  text-decoration: none !important;
  padding: 8px 50px !important;
  text-transform: capitalize !important;
}
.view-profile-button {
  padding-left: 10px !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  background: var(--brand-color-1) !important;
  margin-right: 0.75rem !important;
  margin-left: 5.7rem !important;
  text-decoration: none !important;
  padding: 8px 13px !important;
  text-transform: capitalize !important;
  min-width: 140px !important;
}
.profile-buttons {
  border-radius: 5px !important;
  color: #ffffff !important;
  background: rgba(18, 20, 22, 1) !important;
  text-decoration: none !important;
  padding: 8px 13px !important;
  text-transform: capitalize !important;
}
@media screen and (max-width: 1200px) {
  .up-img {
    margin-top: 5rem;
    margin-left: 7rem !important;
    margin-bottom: 2rem;
    margin-right: 1.5rem !important;
  }
  .pf-imgtext {
    font-size: 12px !important;
    margin-left: 3rem !important;
    margin-bottom: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media screen and (max-width: 650px) {
  .textfield {
    width: 320px !important;
  }
}
@media screen and (max-width: 350px) {
  .textfield {
    width: 250px !important;
  }
}

.card {
  width: 150px;
  margin-top: 4rem;
  margin-left: 6rem !important;
}

.crop_btn {
  color: #ffffff;
  background-color: #ff0000;
  border-style: hidden;
  border-radius: 4px;
  padding: 3px 11px;
  font-weight: 600;
}

#select-image {
  font-weight: 600;
  color: #ffffff;
  background-color: green;
}

.ReactCrop__crop-selection {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
  border-radius: 100%;
}

.modal_container {
  /* height: 350px; */
  /* margin-bottom: 100px; */
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #000000;
}

.modal_img {
  padding: 10px 40px;
}

.modal_header {
  /* border: 1px solid black; */
  height: 35px;
  padding-top: 15px;
  background-color: hsl(0deg 0% 20%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-weight: 500;
  padding-left: 10px;
  /* padding-top: 8px; */
  cursor: auto;
}

.btn_container {
  border: 1px solid black;
  height: 80px;
  background-color: hsl(0deg 0% 20%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  /* justify-content: flex-end;
	align-items: center; */
}

.close_icon:hover {
  background-color: red;
  border-radius: 2px;
  margin-right: 6px;
}

.close_icon {
  margin-right: 4px;
  background-color: rgb(126, 126, 126);
  border-radius: 15px;
}

.save_btn {
  height: 32px;
  width: 150px;
  margin-bottom: 2px;
  /* margin-bottom: 5px; */
  margin-right: 5px;
  border-radius: 15px;
  border: none;
  background-color: rgb(0, 141, 0);
  color: rgb(255, 255, 255);
  font-weight: 500;
  cursor: pointer;
}

.reset_btn {
  height: 32px;
  width: 150px;
  margin-bottom: 2px;
  margin-left: 3px;
  /* margin-bottom: 5px; */
  margin-right: 5px;
  border-radius: 15px;
  border: none;
  background-color: #2374e1;
  color: rgb(255, 255, 255);
  font-weight: 500;
  cursor: pointer;
}

.upload-img-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-family: "Nunito-sans", sans-serif;
}

.upload-img-btn-wrapper .btn {
  position: relative;
  width: 150px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px dashed var(--border-clr1);
  outline: transparent;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-clr2);
  gap: 0.5em;
  outline: transparent;
  overflow: hidden;
  color: var(--font-clr2);
}

.upload-img-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 3;
}

.light-theme .upload-img-btn-wrapper .btn {
  background-color: #f5f5f5;
}

.user_img {
  max-width: 150px;
  aspect-ratio: 1;
  object-fit: contain;
  position: absolute;
  inset: 0;
  transition: filter 250ms ease;
}

.add_new-circle_icon {
  visibility: hidden;
  z-index: 2;
  color: white;
  font-size: 3rem;
  padding: 0;
  margin: 0;
  pointer-events: none;
}

.upload-img-btn-wrapper:hover .add_new-circle_icon {
  visibility: visible;
}

.upload-img-btn-wrapper:hover .user_img {
  filter: blur(2px) saturate(0) contrast(0.5) brightness(0.5);
}
